<template>
    <div class="login">

        <div class="topColor">
        </div>
        <van-row style="margin-top: 8%;">
            <van-col span="24" style="margin-bottom: 1em">
                <van-row justify="center">
                    <van-image width="250" height="70" :src="logoImageUrl" />
                </van-row>
            </van-col>

            <van-col span="24">
                <van-form @submit="onLogin">
                    <van-cell-group inset style="box-shadow: #e0e0e0 1px 1px 5px;">
                        <van-field v-model="userEmail" name="useremail" label="邮箱" placeholder="请输入邮箱"
                            :rules="[{ required: true, message: '请填写邮箱' }]" />
                        <van-field v-model="password" type="password" name="password" label="密码" placeholder="请输入密码"
                            :rules="[{ required: true, message: '请填写密码' }]" />

                        <van-field v-model="caption" name="caption" label="验证码" placeholder="请输入验证码"
                            :rules="[{ required: true, message: '请填写验证码' }]" />
                        <van-field name="uploader" label="">
                            <template #input>
                                <van-image width="138" height="48" :src="captionImage" @click="getCaption" />
                            </template>
                        </van-field>
                    </van-cell-group>

                    <van-row justify="center" style="margin-top: 30px;">
                        <van-col span="22">
                            <van-row justify="space-between">
                                <van-col span="11">
                                    <van-button block type="primary" native-type="submit" color="#343434"
                                        style="border-radius: 4px;">
                                        马上登录
                                    </van-button>
                                </van-col>
                                <van-col span="11">
                                    <van-button plain block native-type="button" color="#343434" to="/reg"
                                        style="border-radius: 4px;">
                                        现在注册
                                    </van-button>
                                </van-col>
                            </van-row>
                        </van-col>
                    </van-row>

                </van-form>
            </van-col>


            <!-- <van-col span="24">
                <van-row justify="center">
                    <van-col span="21" style="margin-bottom: 2em">
                        <span style="color: #f9cf39;">忘记密码</span>
                    </van-col>
                </van-row>
            </van-col> -->

        </van-row>


    </div>
</template>
<script>
export default {
    name: 'login',
    data() {
        return {
            userEmail: '',
            password: '',
            caption: '',
            captionImage: '',
            version: '',
            logoImageUrl: require("@/assets/logo.png"),
        }
    },
    created() {
        // 获得验证码
        this.getCaption();
        // 激活用户选项
        this.$store.commit('setTabbarActive', 4);
        this.$store.commit('setTopShow', false);
    },
    methods: {
        onLogin() {
            // 开始登录
            //console.log("执行登录");
            if (this.userEmail.length > 0 && this.password.length > 0 && this.caption.length > 0) {
                this.$sendAjax.loginUser({ emailName: this.userEmail, password: this.password, caption: this.caption, version: this.version }).then((res) => {
                    if (res.code === 200) {
                        this.$notify({ type: 'success', message: '登录成功' });
                        // 跳转到用户页面
                        //res.data
                        this.$DataTool.setData('token', res.data);
                        this.$router.push({
                            path: '/user'
                        })
                    } else {
                        this.$toast.fail(res.msg);
                        // 获得验证码
                        this.getCaption();
                        this.caption = ''
                    }
                })
            } else {
                this.$toast.fail("请输入完整！");
                //this.$notify({ type: 'danger', message: '请输入完整！' });
            }
        },
        // 获得验证码
        getCaption() {
            this.$sendAjax.getCaption().then((res) => {
                if (res.code === 200) {
                    this.captionImage = res.data.base64
                    this.version = res.data.version
                }
            })
        }
    }
}
</script>
<style>
.topColor {
    top: 0px;
    width: 100%;
    height: 220px;
    background-color: #fec431;
    position: absolute;
    border-radius: 0px 0px 60px 60px;
    z-index: 0px;
}
</style>