let dataTool = {}
// 封装本地存储的方法
dataTool.setData = function (key, value) {
    //对象必须序列化才能存入缓存
    localStorage.setItem(key, JSON.stringify(value));
}

dataTool.getData = function (key) {
    //反序列化
    return JSON.parse(localStorage.getItem(key));
}

dataTool.delData = function (key) {
    localStorage.removeItem(key);
}

export default dataTool;