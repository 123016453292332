import { createStore } from 'vuex'

// 创建一个新的 store 实例
const store = createStore({
    state() {
        return {
            // 顶部显示
            topShow: true,
            bottomShow: true,
            userToken: null,
            // 下选项激活
            vanTabbarActive: 0
        }
    },
    mutations: {
        setTabbarActive(state, val) {
            state.vanTabbarActive = val
        },
        setUserToken(state, val) {
            state.userToken = val
        },
        setTopShow(state, val) {
            if (val === true) {
                state.topShow = true
            } else {
                state.topShow = false
            }
        },
        setBottomShow(state, val) {
            if (val === true) {
                state.bottomShow = true
            } else {
                state.bottomShow = false
            }
        }
    }
})


//暴露出这个对象
export default store;