<template>
    <div class="login">
        <!-- 遮罩层 -->
        <van-overlay :show="loding">
            <div class="wrapper">
                <div class="block">
                    <van-loading size="35" type="spinner" :vertical="defaultTrue" color="white">
                        加载中...
                    </van-loading>
                </div>
            </div>
        </van-overlay>

        <div class="topColor">
        </div>
        <van-row style="margin-top: 8%;">
            <van-col span="24" style="margin-bottom: 1em">
                <van-row justify="center">
                    <van-image width="250" height="70" :src="logoImageUrl" />
                </van-row>
            </van-col>

            <van-col span="24">
                <van-form @submit="onReg">
                    <van-cell-group inset>
                        <van-field v-model="useremail" name="useremail" label="邮箱" placeholder="请输入邮箱"
                            :rules="[{ validator: checkEmail, required: true, message: '请填写邮箱' }]" />
                        <van-field v-model="password1" type="password" name="password1" label="密码" placeholder="请输入密码"
                            :rules="[{ validator: checkPasswordOne, required: true, message: '请填写密码' }]" />
                        <van-field v-model="password2" type="password" name="password2" label="再次输入密码"
                            placeholder="请输入密码"
                            :rules="[{ validator: checkPasswordTwo, required: true, message: '请填写密码' }]" />
                        <van-field v-model="caption" name="caption" label="验证码" placeholder="请输入验证码"
                            :rules="[{ required: true, message: '请填写验证码' }]" />
                        <van-field name="imageCaption" label="">
                            <template #input>
                                <van-image width="138" height="48" :src="captionImage" @click="getCaption" />
                            </template>
                        </van-field>


                        <van-field v-model="emailcaption" center clearable label="邮箱验证码" placeholder="请输入邮箱验证码"
                        :rules="[{ required: true, message: '请填写邮箱验证码' }]">
                            <template #button>
                                <van-button size="mini" color="#343434" block
                                type="primary" @click="sendCheckEmail">{{ sendEmailButtonText }}</van-button>
                            </template>
                        </van-field>

                        <!-- <van-field name="imageCaption" label="邮箱验证">
                            <template #input>
                                <van-button plain type="primary" :disabled="sendEmailButton" size="small"
                                    @click="sendCheckEmail">
                                    {{ sendEmailButtonText }}</van-button>
                            </template>
                        </van-field>

                        <van-field v-model="emailcaption" name="emailcaption" label="邮箱验证码" placeholder="请输入邮箱验证码"
                            :rules="[{ required: true, message: '请填写邮箱验证码' }]" /> -->
                    </van-cell-group>

                    <van-row justify="center" style="margin-top: 30px;">
                        <van-col span="22">
                            <van-row justify="space-between">
                                <van-col span="11">
                                    <van-button block type="primary" :disabled="disabledLogin" native-type="submit"
                                        color="#343434" style="border-radius: 4px;">
                                        现在注册
                                    </van-button>
                                </van-col>
                                <van-col span="11">

                                    <van-button plain block native-type="button" color="#343434"
                                        style="border-radius: 4px;" to="/login">
                                        返回登录
                                    </van-button>
                                </van-col>
                            </van-row>
                        </van-col>
                    </van-row>

                </van-form>
            </van-col>

        </van-row>


    </div>
</template>
<script>
export default {
    name: 'login',
    data() {
        return {
            loding: false,
            defaultTrue: true,
            useremail: '',
            password1: '',
            password2: '',
            caption: '',
            captionImage: '',
            version: '',
            emailcaption: '',
            logoImageUrl: require("@/assets/logo.png"),
            // 是否开启注册按钮
            disabledLogin: true,
            // 是否启用邮箱发送
            sendEmailButton: false,
            // 发送邮箱验证码 按钮文字
            sendEmailButtonText: '发送邮箱验证码',
            // 倒计时
            outTime: 0,
            // 计时器
            outTimeInve: {}
        }
    },
    created() {
        // 获得验证码
        this.getCaption();
        // 激活用户选项
        this.$store.commit('setTabbarActive', 4);
        this.$store.commit('setTopShow', false);
        // 邮箱验证码
        this.startOutTime();
    },
    methods: {
        onReg() {
            // 开始注册
            //regUser
            // 判断验证码是否为空
            if (this.useremail.length > 0 && this.password1.length > 0 && this.emailcaption.length > 0) {
                // emailName password1 emailcaption
                this.$sendAjax.regUser({ emailName: this.useremail, password1: this.password1, emailcaption: this.emailcaption }).then((res) => {
                    if (res.code == 200) {
                        this.$toast.success("注册成功！")
                        let _this = this;
                        setTimeout(() => {
                            _this.$router.push({
                                path: '/login'
                            })
                        }, 1000);
                    }
                })
            }
        },
        // 获得验证码
        getCaption() {
            this.$sendAjax.getCaption().then((res) => {
                if (res.code === 200) {
                    this.captionImage = res.data.base64
                    this.version = res.data.version
                }
            })
        },
        // 检查邮箱是否被注册
        checkEmail(val) {
            this.loding = true
            this.$sendAjax.checkEmail({ emailName: val }).then((res) => {
                this.loding = false
                if (res.code != 200) {
                    this.$toast.fail(res.msg);
                    this.useremail = ''
                    return false
                }
            })

        },
        // 倒计时
        startOutTime() {
            let _this = this;
            this.outTimeInve = window.setInterval(() => {
                if (_this.outTime > 0) {
                    _this.sendEmailButtonText = "发送邮箱验证码(" + _this.outTime + ")"
                    _this.sendEmailButton = true
                    _this.outTime = _this.outTime - 1
                } else {
                    _this.sendEmailButtonText = "发送邮箱验证码"
                    _this.sendEmailButton = false
                }
            }, 1000)
        },
        // 检查密码
        checkPasswordOne(val) {
            if (this.passwordStrong(val) < 1) {
                this.password1 = ''
                this.$toast.fail("密码强度不足，请重新填写！");
                return false;
            }
        },
        // 重复检查密码
        checkPasswordTwo(val) {
            if (this.password1 != this.password2) {
                this.password2 = ''
                this.$toast.fail("两次密码填写不一致，请重新填写！");
                return false;
            }
        },
        // 密码强度
        passwordStrong(val) {
            var model = 0
            if (val.length < 6) return 0
            if (/\d/.test(val)) model++
            if (/[a-z]/.test(val)) model++
            if (/[A-Z]/.test(val)) model++
            if (/\W/.test(val)) model++
            if (val.length > 20) return 4
            return model
        },
        // 发送验证邮件
        sendCheckEmail() {
            if (this.useremail.length > 0 && this.caption.length > 0 && this.version.length > 0) {
                this.loding = true
                this.$sendAjax.sendEmailCaption({ emailName: this.useremail, caption: this.caption, version: this.version }).then((res) => {
                    this.loding = false
                    if (res.code != 200) {
                        this.$toast.fail(res.msg);
                    } else {
                        this.$toast.success("邮件发送成功，请注意查收");
                        this.outTime = 60
                        this.sendEmailButton = true
                        this.disabledLogin = false
                    }
                })
            } else {
                this.$toast.fail("请输入邮箱地址并填写正确的验证码！");
            }
        },
    }
}
</script>