<template>
    <div class="play">
        <van-nav-bar :title="playInfo.title" left-text="返回" left-arrow @click-left="bak" />
        <div id="dplayer" ref="videoRef"></div>
    </div>
</template>
<script>
import Hls from 'hls.js'
import Dplyaer from 'dplayer'

export default {
    name: 'play',
    components: {

    },
    data() {
        return {
            logoImageUrl: require("@/assets/logo.png"),
            playInfo: {},
            dp: null,
            video: {}
        }
    },
    methods: {
        bak() {
            this.$router.go(-1);
        },
        loadVideo() {
            let _this = this;
            this.dp = new Dplyaer({
                container: _this.$refs.videoRef,
                logo: _this.logoImageUrl,
                video: {
                    type: 'customHls',
                    url: _this.playInfo.m3u8url,
                    pic: _this.playInfo.imageurl,
                    customType: {
                        customHls: function (video, player) {
                            const hls = new Hls()
                            hls.loadSource(video.src)
                            hls.attachMedia(video)
                        }
                    }
                },
            });
        }
    },
    created() {
        this.$store.commit('setTopShow', false);
        this.playInfo = this.$route.params
        // 如果路由内没有参数，就跳到首页
        if (this.$route.params.title == null) {
            this.$router.push({
                name: "home",
            });
        }
        // let _this = this;
        // setInterval(() => {
        //     _this.options.src = _this.playInfo.m3u8url
        //     _this.options.title = _this.playInfo.title
        // }, 500)
    },
    mounted() {
        this.loadVideo()
    }
}
</script>