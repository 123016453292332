<template>
  <div id="app">
    <!-- 遮罩层 -->
    <van-overlay :show="loding">
      <div class="wrapper">
        <div class="block">
          <van-loading size="35" type="spinner" :vertical="defaultTrue" color="white">
            加载中...
          </van-loading>
        </div>
      </div>
    </van-overlay>

    <!-- 顶部导航 -->
    <!-- <van-nav-bar :fixed="defaultTrue" :placeholder="defaultTrue" :border="defaultFalse" @click-left="toHome"
      @click-right="searchShowChange" v-if="this.$store.state.topShow">
      <template #left>
        <van-image height="46" widht="153" position="left" fit="contain" :src="logoImageUrl" />
      </template>
      <template #right>
        <van-icon name="search" size="22" color="#000000" />
      </template>
    </van-nav-bar> -->
    <!-- 分类 -->
    <van-sticky :offset-top="50" v-if="this.$store.state.topShow">
      <!-- <van-tabs v-model:active="vanTabsActive" title-active-color="#000" line-width="13" line-height="2"
        color="#f9cf39" :ellipsis="defaultFalse" @click-tab="toSort">
        <van-tab><template #title><span class="txtWidth">首页</span></template></van-tab>
        <van-tab v-for="(o, index) in sort" :name="o.id"><template #title><span class="txtWidth">{{ o.sortName
        }}</span></template>
        </van-tab>
      </van-tabs> -->

      <!-- 搜索 -->
      <transition name="van-slide-down">
        <van-search v-if="searchShow" v-model="searchText" show-action placeholder="请输入搜索关键词，最少两个字符" @search="onSearch"
          @cancel="onCancel" :clearable="defaultFalsh">
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </transition>
    </van-sticky>

    <router-view />

    <!-- <van-row justify="center" style="padding: 2em; background-color: white;">
      <van-col>
        @ SeMeiMei 2022
      </van-col>
    </van-row> -->

    <van-tabbar v-model="this.$store.state.vanTabbarActive" active-color="#f9cf39" inactive-color="#000" :placeholder="defaultTrue"
      :safe-area-inset-bottom="defaultTrue" v-if="this.$store.state.bottomShow">
      <van-tabbar-item icon="wap-home-o" to="/">首页</van-tabbar-item>
      <van-tabbar-item icon="calendar-o" to="/signIn">签到</van-tabbar-item>
      <van-tabbar-item icon="diamond">会员</van-tabbar-item>
      <van-tabbar-item icon="star-o">收藏</van-tabbar-item>
      <van-tabbar-item icon="contact" to="/user">用户</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      defaultTrue: true,
      defaultFalse: false,
      vanTabsActive: 0,
      vanTabbarActive: 0,
      defaultTrue: true,
      logoImageUrl: require("@/assets/logo.png"),
      // 分类列表
      sort: [],
      searchShow: false,
      searchText: "",
      loding: false
    }
  },
  beforeCreate() {
    // 判断手机端和电脑端
    var isWeixin = navigator.userAgent.indexOf('micromessenger') != -1;
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    // 微信访问
    if (isWeixin) {
      window.location.href = 'http://www.semeimei.top'
    }
    if (!flag) {
      window.location.href = 'http://www.semeimei.top'
    }
  },
  mounted() {
    // console.log(this.$store.state.topShow);
    // this.$store.commit('setTopShow', false);
    // console.log(this.$store.state.topShow);
    // 分类内容获取
    //this.getSort();

    //console.log(this.$DataTool.getData('us1'));
    //this.$DataTool.setData('us', 1232134);
  },
  methods: {
    // 获取分类方法
    getSort() {
      if (this.$store.state.topShow) {
        this.$sendAjax.getSort().then((res) => {
          if (res.code === 200) {
            this.sort = res.data
          }
          this.loding = false
        })
      } else {
        this.loding = false
      }
    },
    // 去到分类页面
    toSort(obj) {
      if (obj.name == "0") {
        this.$router.push({
          path: '/'
        })
      } else {
        this.$router.push({
          name: 'sort',
          params: {
            page: 1,
            id: obj.name
          }
        })
      }
    },
    // 搜索
    onSearch(searchText) {
      //console.log(this.searchText);
      if (this.searchText != null && this.searchText !== '' && this.searchText.length > 1) {
        // 开始搜索
        this.$router.push({
          name: 'search',
          params: {
            page: 1,
            searchText: this.searchText
          }
        })
      } else {
        this.$toast.loading({
          message: '请输入最少两个字符以进行搜索',
          forbidClick: true,
          overlay: false,
          type: 'fail',
        });
      }
    },
    // 取消
    onCancel() {
      this.searchText = ""
    },
    // 更改显示状态
    searchShowChange() {
      this.searchShow = !this.searchShow;
      if (!this.searchShow) {
        this.searchText = ""
      }
    },
    // 去到首页
    toHome() {
      this.$router.push({
        path: '/'
      })
    },
  }
}
</script>
<style>
@font-face {
  font-family: 'Joe Font';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('./assets/sourcehansans.woff');
  src: url('./assets/sourcehansans.woff') format('woff');
}

body {
  font-family: 'Joe Font' !important;
  padding: 0px;
  margin: 0px;
  /* background-color: #f5f6f7; */
  background-color: white;
}

/* 导航条高度 */
.van-nav-bar {
  --van-nav-bar-height: 50px;
}

.txtWidth {
  font-size: 17px;
  font-weight: 900;
}

.wrapper {
  display: flex;
  z-index: 999999999;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  text-align: center;
  position: relative;
}

.block>.van-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25% 0 0 -20%;
}

:root{
  --van-nav-bar-background-color: transparent !important;
}
</style>
