<template>
    <div class="playVideo">
        <!-- 遮罩层 -->
        <van-overlay :show="loding">
            <div class="wrapper">
                <div class="block">
                    <van-loading size="35" type="spinner" :vertical="defaultTrue" color="white">
                        加载中...
                    </van-loading>
                </div>
            </div>
        </van-overlay>


        <van-nav-bar left-arrow @click-left="bak" />
        <!-- 播放器以及支付器 -->
        <div style="width: 100%; height: 200px; background-color: black;">
            <div style="width: 100%; height: 200px; background-color: transparent; color: #e1d199; font-size: 15px;"
                v-if="!showVideo">
                <div style="text-align: center; padding-top: 70px; padding-bottom: 1em;">您需要支付积分才能观看</div>

                <van-row gutter="2" justify="center" style="color: black;">
                    <van-col span="6">
                        <van-button type="primary" size="small" @click="payVideo"
                            color="linear-gradient(to right, #e6c883, #e1d099)">支付10积分</van-button>
                    </van-col>
                    <van-col span="8">
                        <van-button type="primary" size="small"
                            color="linear-gradient(to right, #e6c883, #e1d099)">开通会员免费观看</van-button>
                    </van-col>
                </van-row>
            </div>
            <div id="dplayer" ref="videoRef"
                style="width: 100%; height: 200px; background-color: transparent; color: #e1d199; font-size: 15px;"
                ></div>
        </div>

        <div style="margin-top: 1em; padding-left: 1em; font-size: 14px; font-weight: 400;">
            <div>
                {{ title }}
            </div>
            <div style="margin-top: 0.5em;">
                <van-icon name="star-o" /> 收藏
            </div>
            <div style="margin-top: 4em;">
                <img :src="imageurl" style="width: 90%;" :alt="title">
            </div>
        </div>
    </div>
</template>
<script>
import Hls from 'hls.js'
import Dplyaer from 'dplayer'


export default {
    name: 'play',
    components: {

    },
    data() {
        return {
            logoImageUrl: require("@/assets/logo.png"),
            defaultTrue: true,
            defaultFalse: false,
            // 视频编号
            videoId: '',
            // 视频标题
            title: '',
            // 视频播放地址
            m3u8url: '',
            // 视频封面
            imageurl: '',
            // loding页面
            loding: false,
            // 是否需要支付
            needPlay: true,
            // 视频播放器
            dp: null,
            // 视频播放遮罩
            showVideo: false
        }
    },
    created() {
        this.videoId = this.$route.params.videoId
        if (this.videoId != null && this.videoId != '' && this.videoId != undefined) {
            this.getPayVideoAddress()
        } else {
            setTimeout(() => {
                bak()
            }, 2000)
        }

    },
    methods: {
        // 返回
        bak() {
            this.$router.go(-1);
        },
        // 获取视频信息，播放地址
        getPayVideoAddress() {
            this.loding = true
            let _this = this;
            this.$sendAjax.getPlayInfo({ videoId: this.videoId }).then((res) => {
                if (res.code == 998) {
                    _this.$dialog.confirm({
                        title: '提示',
                        message: '视频需要登录后才能观看',
                        cancelButtonText: '返回',
                        confirmButtonText: '去登录',
                        confirmButtonColor: '#323233',
                        cancelButtonColor: '#323233',
                    }).then(() => {
                        // 去登录
                        _this.$router.push({
                            path: '/login'
                        })
                    }).catch(() => {
                        // 返回上一级
                        _this.bak()
                    });
                } else if (res.code == 200) {
                    _this.imageurl = res.data.imgurl
                    _this.m3u8url = res.data.m3u8url
                    _this.title = res.data.title
                    _this.needPlay = res.data.needPlay
                    // 判断是否需要支付
                    if (!_this.needPlay) {  // 直接播放视频。
                        _this.initVideo();
                    }
                } else {
                    _this.$dialog({
                        title: '提示',
                        message: res.msg,
                        confirmButtonText: '返回',
                        confirmButtonColor: '#323233',
                        cancelButtonColor: '#323233',
                    }).then(() => {
                        _this.bak()
                    })
                }
                this.loding = false
            }).then((err) => {
                this.loding = false
            })
        },
        // 支付积分观看视频
        payVideo() {
            let _this = this;
            this.$dialog.confirm({
                title: '确认支付',
                message: '请问确定支付积分观看',
                cancelButtonText: '取消',
                confirmButtonText: '支付积分观看',
                confirmButtonColor: '#323233',
                cancelButtonColor: '#323233',
            }).then(() => {
                // 支付积分
                _this.loding = true;
                this.$sendAjax.payVideo({ videoId: this.videoId }).then((res) => {
                    _this.loding = false;
                    //console.log(res);
                    if (res.code == 200) {
                        // 正常播放
                        _this.m3u8url = res.data.m3u8url
                        _this.needPlay = res.data.needPlay
                        // 判断是否需要支付
                        if (!_this.needPlay) {  // 直接播放视频。
                            _this.initVideo();
                            _this.$notify({ type: 'success', message: '支付成功，请尽情享受吧！' });
                        }
                    } else {
                        // 提示，无法播放
                        _this.$dialog({
                            title: '提示',
                            message: res.msg,
                            confirmButtonText: '确定',
                            confirmButtonColor: '#323233',
                            cancelButtonColor: '#323233',
                        }).then(() => {
                        })
                    }
                }).catch((err) => {
                    _this.loding = false;
                })
            }).catch(() => {
                // 取消
            });
        },
        // 初始化播放器，进行播放
        initVideo() {
            // 播放视频
            if (this.m3u8url != null && this.m3u8url != '' && this.m3u8url != undefined) {
                this.showVideo = true;
                let _this = this;
                this.dp = new Dplyaer({
                    container: _this.$refs.videoRef,
                    logo: _this.logoImageUrl,
                    video: {
                        type: 'customHls',
                        url: _this.m3u8url,
                        pic: _this.imageurl,
                        customType: {
                            customHls: function (video, player) {
                                const hls = new Hls()
                                hls.loadSource(video.src)
                                hls.attachMedia(video)
                            }
                        }
                    },
                });
            }
        },
        // 销毁播放器
        closeVideo() {
            if (this.dp != null) {
                try {
                    this.dp.stopLoad();
                    this.dp.destory();
                } catch (e) {
                    
                }
            }
        }
    },
    // 切换页面了，销毁播放器
    beforeUnmount() {
        this.closeVideo();
    }
}
</script>
<style scoped>
:root {
    --van-nav-bar-icon-color: #77787a !important;
    --van-nav-bar-text-color: #77787a !important;
}
</style>