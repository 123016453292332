<template>
    <div class="newHome">
        <!-- 遮罩层 -->
        <van-overlay :show="loding">
            <div class="wrapper">
                <div class="block">
                    <van-loading size="35" type="spinner" :vertical="defaultTrue" color="white">
                        加载中...
                    </van-loading>
                </div>
            </div>
        </van-overlay>

        <!-- 背景层 -->
        <div id="topDivImage">
            <div v-for="(item, i) in homeData.carousel" :key="i" :id="i">
                <!-- <transition name="van-fade"> -->
                <img v-show="parseInt(i) == parseInt(this.backgroundImageIndex)" :src="item.imageurl"
                    :alt="this.backgroundImageIndex" />
                <!-- </transition> -->
            </div>

        </div>

        <!-- 实际顶部内容 -->
        <div class="compare">
            <!-- logo -->
            <van-row>
                <van-col>
                    <van-image height="46" widht="153" position="left" fit="contain" :src="logoImageUrl" />
                </van-col>
            </van-row>

            <!-- 分类选择 -->
            <van-row justify="space-around"
                style="color: white; font-size: 16px;font-weight: 500; margin-top: 5px; text-align: center;">
                <van-col span="3" class="van-haptics-feedback">最新</van-col>
                <van-col span="3" class="van-haptics-feedback">精选</van-col>
                <van-col span="3" class="van-haptics-feedback">热门</van-col>
                <van-col span="3" class="van-haptics-feedback"><van-icon name="more-o" /></van-col>
            </van-row>

            <!-- 轮播图 -->
            <div style="margin-left: 1em; margin-right: 1em; margin-top: 1em; border-radius: 10px; overflow: hidden;">
                <van-swipe class="se-swipe" :autoplay="3000" indicator-color="white"
                    style="height: 160px; border-radius: 10px;" vertical @change="swipeChange">
                    <van-swipe-item v-for="item in homeData.carousel" :key="item.id" @click="toPlay(item)"
                        style="height: 160px;overflow: hidden; border-radius: 10px;">
                        <van-image width="100%" fit="cover" :src="item.imageurl" :alt="item.imageurl" />
                        <div class="custom-indicator van-ellipsis">{{ item.title }}</div>
                    </van-swipe-item>
                </van-swipe>
            </div>

        </div>




        <div style="margin-top: 70px;"></div>

        <van-row justify="center" style="background-color: white;" v-for="item in homeData.sortList" :key="item.id">
            <van-cell is-link center :border="defaultFalse">
                <!-- 使用 title 插槽来自定义标题 -->
                <template #title>
                    <span class="custom-title">{{ item.title }}</span>
                </template>

                <template #right-icon>
                    <span @click="toSort(item.id)">
                        查看更多&nbsp;
                        <van-icon name="arrow" />
                    </span>
                </template>
            </van-cell>

            <van-col span="24">
                <van-grid square :column-num="2" :gutter="10" :border="defaultFalse">
                    <van-grid-item :key="re.id" v-for="re in item.data" :text="re.title">
                        <van-image style="border-radius: 5px; overflow: hidden; margin-bottom: 0.5em;" width="150"
                            height="150" fit="fill" icon="photo-o" :src="re.imageurl" @click="toPlay(re)">
                            <template v-slot:loading>
                                <van-loading type="spinner" size="20" />
                            </template>
                        </van-image>
                        <span @click="toPlay(re)" class="over-text">{{ re.title }}</span>
                    </van-grid-item>
                </van-grid>
            </van-col>

            <van-row justify="center">
                <van-col class="custom-text-center" @click="toSort(item.id)">
                    查看更多&nbsp;
                    <van-icon name="arrow" />
                </van-col>
            </van-row>
        </van-row>






    </div>
</template>
<script>
export default {
    name: 'newHome',
    created() {
        // 激活首页选项
        this.$store.commit('setTabbarActive', 0);
        this.$store.commit('setTopShow', true);
        this.initHomeData();
    },
    components: {

    },
    data() {
        return {
            defaultTrue: true,
            defaultFalse: false,
            // logo图片
            logoImageUrl: require("@/assets/logo-white.png"),
            // 加载提示
            loding: true,
            // 首页数据
            homeData: {},
            // 轮播下标
            backgroundImageIndex: 0,
        }
    },
    methods: {
        // 初始化首页数据
        initHomeData() {
            this.$sendAjax.getHomeData().then((res) => {
                if (res.code === 200) {
                    this.homeData = res.data
                    this.homeData.carouselRight.forEach(element => {
                        this.homeData.carousel.push(element);
                    });
                    //this.homeData.carousel.push()
                    // this.homeData.carouselRight = null
                    // if (this.homeData.carousel && this.homeData.carousel.length > 0) {
                    //     this.swipeTitle = this.homeData.carousel[0].title + " " + (1) + "/" + this.homeData.carousel.length
                    // }
                }
                this.$forceUpdate();
                this.loding = false
            })
        },
        // 轮播图变化
        swipeChange(index) {
            this.backgroundImageIndex = index;
        },
        // 跳转到播放页面
        toPlay(item) {
            this.$router.push({
                name: 'playVideo',
                params: {
                    videoId: item.id,
                }
            })
        },
        // 跳转到分类页面
        toSort(sortId) {
            this.$router.push({
                name: 'sort',
                params: {
                    page: 1,
                    id: sortId
                }
            })
        }
    }
}
</script>
<style scoped>
.newHome {
    width: 100%;
    overflow: hidden;
}

.compare {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 270px;
    backdrop-filter: blur(20px);
}

#topDivImage {
    background: url("@/assets/bg1.webp");
    height: 200px;
    overflow: hidden;
}


.se-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    height: 160px;
    background-color: white;
}

.custom-indicator {
    position: absolute;
    top: 9em;
    left: 1em;
    font-size: 14px;
    color: white;
    text-shadow: 2px 2px 2px #000000;
}

.over-text {
    font-size: 12px;
    height: 2em;
}

.custom-title {
    font-size: 18px;
    font-weight: 600;
}

.custom-text-center {
    text-align: center;
}
</style>