<template>
    <div class="signIn">

        <!-- 遮罩层 -->
        <van-overlay :show="showLoding">
            <div class="wrapper">
                <div class="block">
                    <van-loading size="35" type="spinner" :vertical="defaultTrue" color="white">
                        加载中...
                    </van-loading>
                </div>
            </div>
        </van-overlay>

        <van-row justify="center">
            <van-col :span="20" style="margin-top: 0em;">
                <h2>签到获取积分</h2>
                <span v-if="!toDaySign">您今天还未签到哦~</span>
                <span v-else>您今天已经完成签到啦！</span>
            </van-col>

            <van-col :span="24" style="margin-top: 1em;">
                <van-calendar :show-title="false" :show-subtitle="false" readonly :lazy-render="true" :poppable="false"
                    :show-confirm="false" :formatter="formatterDate" :min-date="minDate" :default-date="defaultDate"
                    :max-date="maxDate" style="height: 450px;" color="#f9cf39" />
            </van-col>

            <van-col :span="20" style="margin-top: 1em;">
                <van-button block type="primary" native-type="submit" color="#343434" style="border-radius: 4px;"
                    :disabled="toDaySign" @click="makeSign">
                    <span v-if="!toDaySign">立即签到</span>
                    <span v-else>今日已签到</span>
                </van-button>
            </van-col>

            <van-col :span="20" style="margin-top: 1em;">
                下个月签到是新起点，本月签到会清零哦~
            </van-col>

        </van-row>
    </div>
</template>
<script>

export default {
    name: 'signIn',
    data() {
        return {
            minDate: new Date(),
            maxDate: new Date(),
            defaultDate: new Date(),
            // 今日是否签到
            toDaySign: true,
            // 已经签到的日期
            signDateList: [],
            // 显示遮罩
            showLoding: true,
            // 默认tru和false
            defaultTrue: true,
            defaultFalse: false,
        }
    },
    created() {
        // 激活用户选项
        this.$store.commit('setTabbarActive', 1);
        this.$store.commit('setTopShow', false);
        // 检查是否登录
        this.checkToken();
        // 初始化日历
        this.initDate();
    },
    methods: {
        // 验证登录
        checkToken() {
            if (this.$DataTool.getData('token') == null) {
                this.$router.push({
                    path: '/login'
                })
            }
        },
        // 格式化日期
        formatterDate(day) {
            //console.log(day);
            let date = day.date.getDate();
            if (new Date().getDate() == date) {
                day.type = "selected"
                day.text = "今天"
                day.bottomInfo = `未签到`;
            }
            this.signDateList.forEach((item) => {
                let d = new Date(item).getDate();
                if (date === d) {
                    day.type = "selected"
                    day.bottomInfo = `已签倒`;
                }
            })
            return day;
        },
        // 初始化当月最大日期和最小日期
        initDate() {
            let time = new Date();
            this.minDate = new Date(time.getFullYear(), time.getMonth(), 1);
            this.maxDate = new Date(time.getFullYear(), time.getMonth(), 0);
            // 初始化用户签到信息
            let _this = this;
            this.showLoding = true;
            this.$sendAjax.getUserSign().then((res) => {
                if (res.code == 200) {
                    _this.toDaySign = res.data.toDaySign
                    _this.signDateList = res.data.signDateList
                } else {
                    _this.$notify({ type: 'error', message: res.msg });
                }
                _this.showLoding = false;
            });
        },
        // 签到
        makeSign() {
            this.showLoding = true;
            let _this = this;
            this.$sendAjax.makeSign().then((res) => {
                if (res.code == 200) {
                    _this.$notify({ type: 'success', message: '签到成功，积分+' + res.data });
                    _this.initDate();
                }
            })
        }
    }
}
</script>
<style scoped>
.signIn {
    background: linear-gradient(#fec431, white, #f5f5f5);
}
</style>