import { createRouter, createWebHashHistory } from 'vue-router'
// 旧首页
import HomeView from '../views/HomeView.vue'
// 新首页
import NewHomeView from '../views/NewHomeView.vue'

// 分类列表
import Sort from '../views/SortView.vue'
// 搜索结果
import Search from '../views/SearchView.vue'
// 旧播放页面
import Play from '../views/PlayView.vue'
// 用户中心
import User from '../views/user/UserView.vue'
// 登录页面
import Login from '../views/user/LoginView.vue'
// 注册界面
import Reg from '../views/user/RegView.vue'

// 签到页面
import SignIn from '../views/signIn/SignInView.vue'
// 新播放页面
import PlayVideo from '../views/play/PlayVideoView.vue'

const routes = [
  
  { path: '/', name: 'newHome', component: NewHomeView, meta: { title: '首页-新' } },
  { path: '/home', name: 'home', component: HomeView, meta: { title: '首页' } },
  { path: '/sort/:id/:page', name: "sort", component: Sort, meta: { title: '分类' } },
  { path: '/search/:page/:searchText', name: "search", component: Search, meta: { title: '搜索' } },
  { path: '/play', name: "play", component: Play, meta: { title: '播放' } },
  { path: '/user', name: "user", component: User, meta: { title: '用户信息' } },
  { path: '/login', name: "login", component: Login, meta: { title: '登录' } },
  { path: '/reg', name: "reg", component: Reg, meta: { title: '注册' } },

  { path: '/signIn', name: "signIn", component: SignIn, meta: { title: '签到' } },

  
  { path: '/playVideo/:videoId', name: "playVideo", component: PlayVideo, meta: { title: '在线观看' } },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to) => {
  if (to.matched[0]) {
    let title = to.matched[0].meta.title + ""; // 获取到标题
    if ("index1" == title) { // 条件成立时,跳转到指定页面
      router.replace("/profile");
    } else if ("index2" == title) {
      return; // 不做任何操作,直接跳出该方法,所以是不会进行下面修改标题的代码
    }
    document.title = title + "-Semeimei资源欣赏"; // 改变标题
  }
});

export default router
