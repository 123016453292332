<template>
    <div class="sort">
        <!-- 遮罩层 -->
        <van-overlay :show="loding">
            <div class="wrapper">
                <div class="block">
                    <van-loading size="35" type="spinner" :vertical="defaultTrue" color="white">
                        加载中...
                    </van-loading>
                </div>
            </div>
        </van-overlay>

        <van-row justify="center" style="padding: 0.5em; background-color: white;" v-if="pages == 0">
            <van-empty image="error" description="无数据" />
        </van-row>

        <van-row justify="center" style="padding: 0.5em; background-color: white;" v-if="pages != 0">
            <van-col span="24">
                <van-grid square :column-num="2" :gutter="10" :border="defaultFalse">
                    <van-grid-item :key="re" v-for="re in pageList" :text="re.title">
                        <van-image width="150" height="150" fit="fill" :src="re.imageurl" @click="toPlay(re)">
                            <template v-slot:loading>
                                <van-loading type="spinner" size="20" />
                            </template>
                        </van-image>
                        <span @click="toPlay(re)" class="over-text">{{ re.title }}</span>
                    </van-grid-item>
                </van-grid>
            </van-col>
        </van-row>
        <van-row justify="center" style="padding: 0.5em; background-color: white;" v-if="pages != 0">
            <van-col span="24">
                <van-pagination v-model="current" :page-count="pages" @change="changePage" :items-per-page="limitSize"
                    :show-page-size="pagerCount" mode="simple" />
            </van-col>
        </van-row>
    </div>
</template>
<script>
export default {
    name: "sort",
    data() {
        return {
            id: 1,
            // 列表内容
            pageList: [],
            // 总条数
            total: 0,
            // 总页数
            pages: 0,
            // 当前页
            current: 1,
            // 最大按钮数量
            pagerCount: 4,
            // 单页最多内容数量
            limitSize: 16,
            defaultFalse: false,
            defaultTrue: true,
            // 加载中
            loding: true
        };
    },
    created() {
        this.$store.commit('setTopShow', true);
        if (this.$route.params.id != null && this.$route.params.page != null) {
            this.id = parseInt(this.$route.params.id);
            this.current = parseInt(this.$route.params.page);
        } else {
            this.id = 1;
            this.current = 1;
        }

        this.defaultAjax();
    },
    watch: {
        '$route'(to, from) {
            if (this.$route.params.id) {
                this.id = parseInt(this.$route.params.id);
                this.current = parseInt(this.$route.params.page);
                this.defaultAjax();
            }
        },
    },
    methods: {
        // 加载内容
        defaultAjax() {
            this.loding = true
            this.$sendAjax.getlist({ page: this.current, limit: this.limitSize, type: this.id }).then((res) => {
                if (res.code === 200) {
                    this.pageList = res.data.records
                    this.total = res.data.total
                    this.pages = res.data.pages
                    this.current = res.data.current
                }
                this.$forceUpdate();
                this.loding = false
            })

        },
        // 更改页数
        changePage(nowPage) {
            this.pageList = []
            this.$router.push({
                name: 'sort',
                params: {
                    page: nowPage,
                    id: this.id
                }
            })
        },
        // 去到播放列表
        toPlay(item) {
            this.$router.push({
                name: "play",
                params: item,
            });
        },
    }
}
</script>