<template>
    <div class="search">
        <!-- 遮罩层 -->
        <van-overlay :show="loding">
            <div class="wrapper">
                <div class="block">
                    <van-loading size="35" type="spinner" :vertical="defaultTrue" color="white">
                        加载中...
                    </van-loading>
                </div>
            </div>
        </van-overlay>


        <van-row justify="center" style="padding: 0.5em; background-color: white;" v-if="pages == 0">
            <van-empty image="error" description="无数据" />
        </van-row>

        <van-row justify="center" style="padding: 0.5em; background-color: white;" v-if="pages != 0">
            <van-col span="24">
                <van-grid square :column-num="2" :gutter="10" :border="defaultFalse">
                    <van-grid-item :key="re" v-for="re in pageList" :text="re.title">
                        <van-image width="150" height="150" fit="fill" :src="re.imageurl" @click="toPlay(re)">
                            <template v-slot:loading>
                                <van-loading type="spinner" size="20" />
                            </template>
                        </van-image>
                        <span @click="toPlay(re)" class="over-text">{{ re.title }}</span>
                    </van-grid-item>
                </van-grid>
            </van-col>
        </van-row>
        <van-row justify="center" style="padding: 0.5em; background-color: white;" v-if="pages != 0">
            <van-col span="24">
                <van-pagination v-model="current" :page-count="pages" @change="changePage" :items-per-page="limitSize"
                    :show-page-size="pagerCount" />
            </van-col>
        </van-row>
    </div>
</template>
<script>
export default {
    name: "search",
    data() {
        return {
            // 列表内容
            pageList: [],
            // 总条数
            total: 0,
            // 总页数
            pages: 0,
            // 当前页
            current: 1,
            // 最大按钮数量
            pagerCount: 4,
            // 单页最多内容数量
            limitSize: 16,
            // 搜索内容
            searchText: "",
            // 默认否
            defaultFalse: false,
            // loding框
            loding: true,
            defaultTrue: true
        }
    },
    methods: {
        // 跳转到播放页面
        toPlay(item) {
            this.$router.push({
                name: "play",
                params: item,
            });
        },
        defaultAjax() {
            this.loding = true
            this.$sendAjax.getSearch({ page: this.current, limit: this.limitSize, searchText: this.searchText }).then((res) => {
                if (res.code !== 200) {
                    this.$toast.loading({
                        message: '搜索过快，请稍后再试',
                        forbidClick: true,
                        overlay: false,
                        type: 'fail',
                    });
                } else {
                    this.pageList = res.data.records
                    this.total = res.data.total
                    this.pages = res.data.pages
                    this.current = res.data.current
                }
                this.loding = false
            })
        },
        changePage(nowPage) {
            // 开始搜索
            this.$router.push({
                name: 'search',
                params: {
                    page: nowPage,
                    searchText: this.searchText
                }
            })
        }
    },
    created() {
        this.$store.commit('setTopShow', true);
        this.searchText = this.$route.params.searchText
        this.current = parseInt(this.$route.params.page)
        this.defaultAjax()
    },
    watch: {
        '$route'(to, from) {
            if (this.$route.params.searchText && this.$route.params.page) {
                this.searchText = this.$route.params.searchText
                this.current = parseInt(this.$route.params.page)
                this.defaultAjax()
            }
        }
    },
    filters: {
        ellipsis(value) {
            if (!value) return ''
            if (value.length > 16) {
                return value.slice(0, 16) + '...'
            }
            return value
        }
    },
}
</script>