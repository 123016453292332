import https from './http.js';

//设置个对象，就不用一个个暴露了，直接暴露对象
let sendAjax = {};

/* 获取列表 */
//查询列表，详情就是get
/* /api/getlist是请求接口地址，有http.js里面的Ip加上，如：http:192.168.0.1:9090//api/getlist  */
// 获取分页数据
sendAjax.getlist = function (params) {
	return https.post('/api/default/sort/list', params)
}
// 获取分类数据
sendAjax.getSort = function () {
	return https.post('/api/default/sort', {})
}
// 搜索
sendAjax.getSearch = function (params) {
	return https.post('/api/default/search', params)
}
// 获得首页数据
sendAjax.getHomeData = function() {
	return https.get('/api/default/home',{})
}
// 获得验证码
sendAjax.getCaption = function() {
	return https.post('/api/default/caption',{})
}
// 验证邮箱
sendAjax.checkEmail = function(params) {
	return https.get('/api/default/checkEmail',params)
}
// 发送验证邮件
sendAjax.sendEmailCaption = function(params) {
	return https.post('/api/default/emailCaptcha', params)
}
// 注册
sendAjax.regUser = function(params) {
	return https.post('/api/default/regUser', params)
}
// 登录
sendAjax.loginUser = function(params) {
	return https.post('/api/default/loginUser', params)
}
// 获取用户信息
sendAjax.getUserInfo = function() {
	return https.post('/api/user/getUserInfo', {})
}
// 获取视频播放信息
sendAjax.getPlayInfo = function(params) {
	return https.post('/api/play/getVideoPlay', params)
}
// 支付积分购买视频
sendAjax.payVideo = function(params) {
	return https.post('/api/play/payVideo', params)
}
// 获取用户签到信息
sendAjax.getUserSign = function() {
	return https.post('/api/signRecord/getSignRecord', null)
}
// 进行签到
sendAjax.makeSign = function() {
	return https.post('/api/signRecord/makeSignIn', null)
}
//暴露出这个对象
export default sendAjax;