<template>
    <div class="homeView">
        <!-- 遮罩层 -->
        <van-overlay :show="loding">
            <div class="wrapper">
                <div class="block">
                    <van-loading size="35" type="spinner" :vertical="defaultTrue" color="white">
                        加载中...
                    </van-loading>
                </div>
            </div>
        </van-overlay>

        <!-- 轮播图 -->
        <van-row>
            <van-swipe class="y-swipe" :autoplay="5000" indicator-color="white" @change="swipeChange">
                <van-swipe-item v-for="item in homeData.carousel" @click="toPlay(item)" :key="item.id">
                    <img :src="item.imageurl" class="swipe-img" :alt="item.imageurl">
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">{{ swipeTitle }}</div>
                </template>
            </van-swipe>

        </van-row>

        <van-row style="margin-top: 1em;">
        </van-row>

        <van-row justify="center" style="padding: 0.5em; background-color: white;" v-for="item in homeData.sortList"
            :key="item.id">
            <van-cell is-link center :border="defaultFalse" @click="toSort(item.id)">
                <!-- 使用 title 插槽来自定义标题 -->
                <template #title>
                    <span class="custom-title">{{ item.title }}</span>
                </template>

                <template #right-icon>
                    查看更多&nbsp;
                    <van-icon name="arrow" />
                </template>
            </van-cell>

            <van-col span="24">
                <van-grid square :column-num="2" :gutter="10" :border="defaultFalse">
                    <van-grid-item :key="re.id" v-for="re in item.data" :text="re.title">
                        <van-image width="150" height="150" fit="fill" icon="photo-o" :src="re.imageurl"
                            @click="toPlay(re)">
                            <template v-slot:loading>
                                <van-loading type="spinner" size="20" />
                            </template>
                        </van-image>
                        <span @click="toPlay(re)" class="over-text">{{ re.title }}</span>
                    </van-grid-item>
                </van-grid>
            </van-col>

            <van-row justify="center">
                <van-col class="custom-text-center" @click="toSort(item.id)">
                    查看更多&nbsp;
                    <van-icon name="arrow" />
                </van-col>
            </van-row>
        </van-row>
    </div>
</template>

<script>
export default {
    name: 'homeView',
    created() {
        // 激活首页选项
        this.$store.commit('setTabbarActive', 0);
        this.initHome()
        this.$store.commit('setTopShow', true);
    },
    data() {
        return {
            defaultTrue: true,
            defaultFalse: false,
            homeData: {},
            swipeTitle: "",
            loding: true
        }
    },
    methods: {
        initHome() {
            this.loding = true
            this.$sendAjax.getHomeData().then((res) => {
                if (res.code === 200) {
                    this.homeData = res.data
                    this.homeData.carouselRight.forEach(element => {
                        this.homeData.carousel.push(element);
                    });
                    //this.homeData.carousel.push()
                    this.homeData.carouselRight = null
                    if (this.homeData.carousel && this.homeData.carousel.length > 0) {
                        this.swipeTitle = this.homeData.carousel[0].title + " " + (1) + "/" + this.homeData.carousel.length
                    }
                }
                this.$forceUpdate();
                this.loding = false
            })
        },
        // 轮播器标题
        swipeChange(index) {
            this.swipeTitle = this.homeData.carousel[index].title + " " + (index + 1) + "/" + this.homeData.carousel.length
        },
        // 跳转分类页面
        toSort(sortId) {
            this.$router.push({
                name: 'sort',
                params: {
                    page: 1,
                    id: sortId
                }
            })
        },
        // 跳转到播放页面
        toPlay(item) {
            this.$router.push({
                name: "play",
                params: item,
            });
        },
    },
}
</script>
<style scoped>
.y-swipe {
    margin-top: 0.5em;
}

.y-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    height: 211px;
    text-align: center;
    background-color: white;
}

.swipe-img {
    width: 99%;
    height: 100%;
}

.custom-indicator {
    position: fixed;
    right: 1em;
    bottom: 1em;
    font-size: 18px;
    color: white;
    text-shadow: 2px 2px 2px #000000;
}

.custom-title {
    font-size: 22px;
    font-weight: 900;
}

.custom-text-center {
    text-align: center;
}

.van-image {
    overflow: hidden;
    height: 170px;
}


</style>