<template>
    <div class="userInfo">

        <van-row justify="center">
            <van-col :span="20" style="margin-top: 2em;">
                <van-row justify="end">
                    <van-icon name="setting-o" size="20" @click="configShowM" />
                </van-row>
            </van-col>
            <van-col :span="21" style="margin-top:1em">
                {{ userName }} ({{ getVipLevel() }})
                <van-icon name="friends" v-if="vipLevel == 0" />
                <van-icon name="diamond-o" color="red" v-if="vipLevel == 1" />
                <van-icon name="diamond" color="red" v-if="vipLevel == 2" />
            </van-col>

            <!-- 余额 -->
            <van-col :span="21" style="margin-top:1em; 
                background-color: white;
                border-radius: 10px;
                box-shadow: #e0e0e0 1px 1px 5px;">
                <van-grid :border="false" :column-num="2">
                    <van-grid-item>
                        <span style="font-weight: bold;font-size: 19px;">{{ userIntegral }}</span>
                        <span style="font-size:12px">现有积分</span>
                    </van-grid-item>

                    <van-grid-item>
                        <span style="font-weight: bold;font-size: 19px;">{{ userMoney }}</span>
                        <span style="font-size:12px">现有余额</span>
                    </van-grid-item>
                </van-grid>
            </van-col>

            <!-- 会员 -->
            <van-col :span="21"
                style="margin-top:1em; background: linear-gradient(120deg,#f3cb9b,#fbdcbc); color: #643814;border-radius: 10px; padding: 0.5em; box-shadow: #e0e0e0 1px 1px 5px;">
                <van-icon name="vip-card-o" color="#643814" size="24" /> <span
                    style="font-size:18px; line-height: 24px; font-weight: bold;">会员权益</span>
                <br>
                <van-row justify="space-around" style="font-size: 14px; margin-top: 0.8em;">
                    <van-col style="text-align: center;">
                        <van-icon name="fire-o" />
                        <br>
                        无限观看
                    </van-col>

                    <van-col style="text-align: center;">
                        <van-icon name="gem-o" />
                        <br>
                        无广告
                    </van-col>

                    <van-col style="text-align: center;">
                        <van-icon name="good-job-o" />
                        <br>
                        积分翻倍
                    </van-col>
                </van-row>
            </van-col>

            <van-col :span="21"
                style="margin-top:1em; background-color: white;border-radius: 10px; padding: 0.5em; box-shadow: #e0e0e0 1px 1px 5px;">
                <van-grid :border="false" :column-num="4">
                    <van-grid-item>
                        <span style="font-weight: bold;">
                            <van-icon name="star-o" size="20" />
                        </span>
                        <span style="font-size:14px">我的收藏</span>
                    </van-grid-item>

                    <van-grid-item>
                        <span style="font-weight: bold;">
                            <van-icon name="play-circle-o" />
                        </span>
                        <span style="font-size:14px">历史观看</span>
                    </van-grid-item>

                    <van-grid-item>
                        <span style="font-weight: bold;">
                            <van-icon name="point-gift-o" />
                        </span>
                        <span style="font-size:14px">积分签到</span>
                    </van-grid-item>

                    <van-grid-item>
                        <span style="font-weight: bold;">
                            <van-icon name="gem-o" />
                        </span>
                        <span style="font-size:14px">成为会员</span>
                    </van-grid-item>
                </van-grid>
            </van-col>

        </van-row>

        <van-row justify="center" style="margin-top: 1em;">
            <van-col span="22">
                观看历史
            </van-col>
        </van-row>


        <!-- 上拉菜单 -->
        <van-action-sheet v-model:show="configShow" :actions="actions" cancel-text="取消" @select="configClick"
            @cancel="configCloseM" />
    </div>
</template>
<script>
export default {
    name: 'userInfo',
    data() {
        return {
            userName: '',
            userEmail: '',
            userMoney: 0.0,
            userIntegral: 0,
            vipLevel: 0,
            vipOutTime: 0,
            configShow: false,
            actions: [
                { name: "修改用户名", type: 1 },
                { name: "修改密码", type: 2 },
                { name: "退出登录", type: 3 },
            ]
        }
    },
    created() {
        // 激活用户选项
        this.$store.commit('setTabbarActive', 4);
        this.$store.commit('setTopShow', false);
        this.checkToken();
    },
    methods: {
        // 检测登录情况
        checkToken() {
            if (this.$DataTool.getData('token') == null) {
                this.$router.push({
                    path: '/login'
                })
            }
            // 获取用户信息
            this.$sendAjax.getUserInfo({}).then((res) => {
                if (res.code == 200) {
                    this.userName = res.data.userName
                    this.userEmail = res.data.userEmail
                    this.userMoney = res.data.userMoney
                    this.userIntegral = res.data.userIntegral
                    this.vipLevel = res.data.vipLevel
                    this.vipOutTime = res.data.vipOutTime
                } else {
                    this.$router.push({
                        path: '/login'
                    })
                }
            })
        },
        // 获取会员等级
        getVipLevel() {
            let level = "非会员";
            if (this.vipLevel == 1) {
                level = "普通会员"
            } else if (this.vipLevel == 2) {
                level = "超级会员"
            }
            return level;
        },
        // 面板显示
        configShowM() {
            this.configShow = true;
        },
        // 面板关闭
        configCloseM() {
            this.configShow = false;
        },
        // 面板点击事件
        configClick(item) {
            switch (item.type) {
                case 3:
                    this.$dialog.confirm({
                        title: '提示',
                        message:
                            '确定要退出登录吗？',
                    })
                        .then(() => {
                            // 确定退出  去除缓存数据
                            this.$DataTool.delData('token');
                            // 关闭弹窗，跳转回未登录界面
                            this.configShow = false;
                            this.$notify({ type: 'success', message: '已安全退出登录账号' });
                            this.$router.push({
                                path: '/login'
                            });
                        })
                        .catch(() => {
                            // on cancel
                        });
                    break;
                default:
                    break;
            }
        }
    }
}
</script>
<style scoped>
.userInfo {
    background: linear-gradient(#fec431, white, #f5f5f5);
}
</style>