import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import sendAjax from "@/api/api.js";
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
// 工具类
import dataTool from '@/utils/localStorage.js';


//创建app
const app = createApp(App);
// 挂载api
app.config.globalProperties.$sendAjax = sendAjax
// 挂载读写缓存工具
app.config.globalProperties.$DataTool = dataTool
// 播放组件
app.use(router)
    .use(store)
    .use(Vant)
    .use(VueAxios, axios)
    .mount('#app');
export default app